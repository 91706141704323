<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>{{translate('New stored query')}}</v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-btn
        text
        :loading="details.loading"
        :disabled="!valid"
        @click="save"
        >
        <v-icon>save</v-icon> {{translate('Save')}}
      </v-btn>
    </template>

    <v-card>
      <v-card-text>
        <v-form v-model="valid">
          <v-text-field
            :label="translate('Id')"
            v-model="details.query.storedQueryId"
            :loading="details.loading"
            required
            :rules="validationRules.notEmpty()"
            />
          <v-text-field
            :label="translate('Description')"
            v-model="details.query.description"
            :enabled="false"
            :loading="details.loading"/>
        </v-form>
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'

import uuid from 'uuidv4'

export default {
  props: ['apiKey'],
  data: () => ({
    mutations: 1,
    valid: true,
  }),
  components: {
    LayoutPage
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['api', 'translate', 'validationRules']),
  },
  asyncComputed: {
    details: {
      async get () {
        return {
          loading: false,
          query: {
            storedQueryId: uuid().replace(/-/g, ''),
            description: ''
          }
        }
      },
      default: {
        loading: true,
        query: {}
      }
    }
  },
  methods: {
    async save () {
      await this.api.storedQueries.updateStoredQuery(this.details.query, {query: {'create-only': 1}})
      this.$router.replace({
        name: 'edit-stored-query',
        params: {
          storedQueryId: this.details.query.storedQueryId
        }
      })
    }
  }
}
</script>
